import { Player } from '../../../player/player.types';
import { playerNotExistsErr } from './errors';
import { filterUndefined, isEmpty, last } from '../../../../helpers/arrays';
import {
  cardsAndActionTypesToActions,
  ignoreProvidedActionTypes,
  PROBABILITY_CAPSULE_ACTIONS,
  toImpactedAction,
  toInProgressAction,
} from '../../utils/turnActions';
import { ApplyRule, CardNames, PlayingCard } from '../../../card/cardTypes';
import { GameState } from '../hooks/useGameState';
import { APPLIED, IMPACTED } from '../../gameTypes';

export const getPlayer = (playersList: Player[], playerId: string): Player => {
  const foundPlayer = playersList.find((player) => player.id === playerId);
  if (foundPlayer === undefined) {
    throw playerNotExistsErr(playerId);
  }

  return foundPlayer;
};

export const getCurrentPlayer = (gameState: GameState): Player =>
  gameState.allPlayers.filter((player) => player.isCurrent)[0];

export const getAllPlayerIds = (gameState: GameState): string[] => gameState.allPlayers.map((player) => player.id);

export const getOtherPlayers = (gameState: GameState): Player[] =>
  gameState.allPlayers.filter((player) => !player.isCurrent);

export const getAttackingPlayerId = (gameState: GameState): string => {
  const lastAction = last(
    ignoreProvidedActionTypes(gameState.turnActions, [
      'create log',
      ...PROBABILITY_CAPSULE_ACTIONS,
      toInProgressAction(CardNames.DOME),
      toImpactedAction(CardNames.DOME),
      ...cardsAndActionTypesToActions([CardNames.TELEPORT, CardNames.DISINTEGRATOR], [IMPACTED]),
      ...cardsAndActionTypesToActions(
        [CardNames.UNCHAINED_ESSENCE, CardNames.MASS_ESSENCE_ESCAPE],
        [APPLIED, IMPACTED]
      ),
    ])
  );
  return !!lastAction && lastAction.action === toImpactedAction(CardNames.SPACE_DISTORTION)
    ? lastAction.player
    : gameState.activePlayer;
};

export const getPlayerEnergySourceCards = (player: Player): PlayingCard[] => {
  const attackingCards = [getPlayerAppliedGauntlet(player)];
  attackingCards.push(...getPlayerEnergyCards(player));
  return filterUndefined(attackingCards);
};

export const getPlayerAppliedGauntlet = (player: Player): PlayingCard | undefined =>
  player.cardsOnTable.filter((card) => card.applyRule === ApplyRule.GAUNTLET)[0];

export const getPlayerAppliedDome = (player: Player): PlayingCard | undefined =>
  player.cardsOnTable.filter((card) => card.name === CardNames.DOME)[0];

export const getPlayerEnergyCards = (player: Player): PlayingCard[] =>
  player.cardsInHand.filter((card) => card.applyRule === ApplyRule.ENERGY);

export const getPlayerCards = (player: Player): PlayingCard[] => [...player.cardsInHand, ...player.cardsOnTable];

export const getPlayerAppliedShell = (player: Player): PlayingCard | undefined =>
  player.cardsOnTable.filter((card) => card.name === CardNames.SHELL)[0];

export const isPlayerOutOfCards = (allPlayers: Player[], playerId: string): boolean =>
  isEmpty(getPlayerCards(getPlayer(allPlayers, playerId)));
