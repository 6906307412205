import React from 'react';
import { AnimationContext, AnimationContextType, Props } from './animationContextProvider.types';
import { Props as MovingCardsProps } from '../../../components/movingCards/movingCards.types';
import { Props as MovingEssenceProps } from '../../../components/movingEssence/movingEssence.types';
import { Props as PredictionTargetProps } from '../../../components/predictionTarget/predictionTarget.types';
import { MovingCards } from '../../../components/movingCards';
import { isEmpty } from '../../../helpers/arrays';
import { withFunc } from '../../../helpers/utils';
import { MovingEssence } from '../../../components/movingEssence';
import { usePulsatingCards } from './usePulsatingCards';
import { PredictionTarget } from '../../../components/predictionTarget';
import { CardMovementDrawer } from '../../../components/movingCards/cardMovementDrawer';

export const AnimationContextProvider: React.FC<Props> = ({ children }) => {
  const [movingCardsPropsMatrix, setMovingCardsPropsMatrix] = React.useState<MovingCardsProps[][]>([]);
  const [movingEssencesProps, setMovingEssencesProps] = React.useState<MovingEssenceProps[]>([]);
  const [predictionTargetProps, setPredictionTargetProps] = React.useState<PredictionTargetProps[]>([]);

  const [animatingCards, setAnimatingCards] = React.useState<React.ReactElement[]>([]);
  const [animatedCardsMovement, setAnimatedCardsMovement] = React.useState<React.ReactElement[]>([]);
  const [animatingEssences, setAnimatingEssences] = React.useState<React.ReactElement>();
  const [animatingPredictionTargets, setAnimatingPredictionTargets] = React.useState<React.ReactElement>();

  const clearAnimatingCards = () => setAnimatingCards([]);
  const clearAnimatingEssences = () => setAnimatingEssences(undefined);

  React.useEffect(() => {
    if (!isEmpty(animatingCards) || isEmpty(movingCardsPropsMatrix)) return;

    setMovingCardsPropsMatrix((prevMatrix) => {
      const movingCardsPropsListToAnimate = prevMatrix[0];
      const movingCardsToAnimate = movingCardsPropsListToAnimate.map((props, index) => (
        <MovingCards key={index} {...props} />
      ));
      const cardsMovement = movingCardsPropsListToAnimate.map((props, index) => (
        <CardMovementDrawer key={index + Math.random()} stops={props.stops} cards={props.cards} />
      ));
      setAnimatedCardsMovement(cardsMovement);
      setAnimatingCards(movingCardsToAnimate);
      return [...prevMatrix.slice(1)];
    });
    return;
  }, [animatingCards.length, movingCardsPropsMatrix.length]);

  React.useEffect(() => {
    if (animatingEssences || isEmpty(movingEssencesProps)) return;

    movingEssencesProps[movingEssencesProps.length - 1].runAfter = clearAnimatingEssences;

    const disperserEssenceBoxes = Array.from({ length: movingEssencesProps.length }, (_, index) => (
      <MovingEssence key={'moving-essence-' + index} {...movingEssencesProps[index]} />
    ));

    setAnimatingEssences(<>{disperserEssenceBoxes}</>);
    setMovingEssencesProps([]);
  }, [animatingEssences, movingEssencesProps.length]);

  React.useEffect(() => {
    if (animatingPredictionTargets || isEmpty(predictionTargetProps)) return;

    const targetBlocks = Array.from({ length: predictionTargetProps.length }, (_, index) => (
      <PredictionTarget key={'prediction-target-' + index} {...predictionTargetProps[index]} />
    ));

    setAnimatingPredictionTargets(<>{targetBlocks}</>);
    setPredictionTargetProps([]);
  }, [animatingPredictionTargets, predictionTargetProps.length]);

  const withStopAnimation = (props: MovingCardsProps): MovingCardsProps => ({
    ...props,
    runAfter: props.runAfter ? withFunc(props.runAfter, clearAnimatingCards) : clearAnimatingCards,
  });

  const queueMovingCardsProps = (...propsMatrix: MovingCardsProps[][]) => {
    const propsWithAnimationStopMatrix = propsMatrix.map((propsList) =>
      propsList.map((props) => withStopAnimation(props))
    );

    setMovingCardsPropsMatrix((prevMatrix) => [...prevMatrix, ...propsWithAnimationStopMatrix]);
  };

  const queueMovingEssencesProps = (propsList: MovingEssenceProps) => {
    setMovingEssencesProps((prevState) => {
      const stateString = JSON.stringify(prevState);
      if (stateString.includes(JSON.stringify(propsList))) {
        return prevState;
      } else {
        return [...prevState, propsList];
      }
    });
  };

  const queuePredictionTargetProps = (propsList: PredictionTargetProps) => {
    setPredictionTargetProps((prevState) => {
      const stateString = JSON.stringify(prevState);
      if (stateString.includes(JSON.stringify(propsList))) {
        return prevState;
      } else {
        return [...prevState, propsList];
      }
    });
  };

  const usePulsatingCardsResult = usePulsatingCards();

  const initializeContext = (): AnimationContextType => ({
    animatingCards: animatingCards,
    animatedCardsMovement: animatedCardsMovement,
    animatingEssences: animatingEssences,
    animatingPredictionTargets: animatingPredictionTargets,
    setAnimatingPredictionTargets: setAnimatingPredictionTargets,
    queueMovingCardsProps: queueMovingCardsProps,
    queueMovingEssencesProps: queueMovingEssencesProps,
    queuePredictionTargetProps: queuePredictionTargetProps,
    ...usePulsatingCardsResult,
  });

  return <AnimationContext.Provider value={initializeContext()}>{children}</AnimationContext.Provider>;
};
