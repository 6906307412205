import axios from 'axios';
import { getHttpStatusFromErr } from '../helpers/utils';
import { ErrorEvent } from '../helpers/hooks/useErrorHandling';

export const IGNORE_GAME_BLOCK_ON_ERROR_HEADER = 'Ignore-Game-Block-On-Error';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config?.headers[IGNORE_GAME_BLOCK_ON_ERROR_HEADER]) return Promise.reject(error);

    const statusCode = getHttpStatusFromErr(error);
    if (statusCode === 403) ErrorEvent.emit('accessDenied', error);
    if (statusCode && statusCode >= 500) ErrorEvent.emit('unexpectedError', error);
    return Promise.reject(error);
  }
);
