import React from 'react';
import styles from './movingCards.module.css';
import { cardToComponent } from '../../helpers/transformers';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { Card } from '../card';
import { Props, OneMove, durationConfig, fixYCoordinate } from './movingCards.types';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { useWindowSize } from '../../helpers/utils';
import { Location } from '../../features/game/locationTrackerContextProvider';
import { PlayingCard } from '../../features/card/cardTypes';
import { isEmpty } from '../../helpers/arrays';
import { AppContext } from '../../global/context/appContext';

export const MovingCards: React.FC<Props> = ({ stops, cards, runAfter, runWith }) => {
  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { isMobile } = React.useContext(AppContext);
  const { dimension } = useCardDimension({ size: cardSize });
  const { width, height } = useWindowSize();

  const mapWithCardOffset = (stop: Location): Location => {
    return {
      x: stop.x - dimension / 2,
      y: stop.y - dimension / 2,
    };
  };

  const [stopsToGo, setStepsToGo] = React.useState([...stops].map(mapWithCardOffset));
  const [currentMove, setCurrentMove] = React.useState<OneMove>();
  const [moveKey, setMoveKey] = React.useState(0);

  const offset = dimension;
  const limitValue = (value: number, limit: number) => {
    if (value < 0) return 0;
    return value + offset > limit ? limit - offset : value;
  };

  const setCurrentMoveWithLimits = (move?: OneMove) => {
    const checkedMove =
      move !== undefined
        ? {
            initial: {
              x: limitValue(move.initial.x, width),
              y: limitValue(fixYCoordinate(move.initial.y, isMobile), height),
            },
            final: {
              x: limitValue(move.final.x, width),
              y: limitValue(fixYCoordinate(move.final.y, isMobile), height),
            },
          }
        : move;

    setCurrentMove(checkedMove);
  };

  const calculateAnimationDuration = (): number => {
    const { minInSec, maxInSec, stopsForMin, stopsForMax } = durationConfig;
    if (stops.length <= stopsForMin) return minInSec;
    if (stops.length >= stopsForMax) return maxInSec;

    const proportion = (stops.length - stopsForMin) / (stopsForMax - stopsForMin);
    return minInSec + (maxInSec - minInSec) * proportion;
  };

  const animationDuration = calculateAnimationDuration();
  const animationSec = animationDuration * 0.7;
  const delayBeforeDisappearingSec = animationDuration - animationSec;

  const handleMove = () => {
    if (stopsToGo.length === 1) {
      setTimeout(() => {
        setCurrentMoveWithLimits(undefined);

        if (runAfter) runAfter();
      }, delayBeforeDisappearingSec * 1000);
      return;
    }

    setCurrentMoveWithLimits({ initial: stopsToGo[0], final: stopsToGo[1] });
    setStepsToGo(stopsToGo.slice(1));
    setMoveKey((prevKey) => prevKey + 1);
  };

  React.useEffect(() => {
    if (height === 0) return;
    handleMove();
  }, [height]);

  const moveDuration = animationSec / (stops.length - 1);

  const otherCardsTotalDelay = 0.5;
  const cardsDelays = [...Array(cards.length)].map((_, index) => (index * otherCardsTotalDelay) / (cards.length - 1));

  const movingCard = (cardToAnimate: PlayingCard, delay: number, onAnimationComplete?: () => void) => {
    const key = `moving-card-${cardToAnimate.id}-${moveKey}`;

    return currentMove ? (
      <div
        key={key}
        data-testid={`moving-card-${cardToAnimate.id}`}
        className={styles.movingCard}
        style={
          {
            '--initial-x': `${currentMove.initial.x}px`,
            '--initial-y': `${currentMove.initial.y}px`,
            '--final-x': `${currentMove.final.x}px`,
            '--final-y': `${currentMove.final.y}px`,
            '--duration': `${moveDuration}s`,
            '--delay': `${delay > 0 ? delay : 0}s`,
          } as React.CSSProperties
        }
        ref={(el) => {
          if (el) {
            el.style.transform = `translate(${currentMove.initial.x}px, ${currentMove.initial.y}px)`;
          }
        }}
        onAnimationStart={(e) => {
          if (e.currentTarget) {
            e.currentTarget.style.opacity = '1';
          }
        }}
        onAnimationEnd={() => {
          if (onAnimationComplete) {
            onAnimationComplete();
          }
        }}
      >
        <Card {...cardToComponent(cardToAnimate, cardSize)} />
      </div>
    ) : (
      <React.Fragment key={key} />
    );
  };

  const canPlayAnimation = () => stops.length > 1 && !isEmpty(cards);
  React.useEffect(() => {
    if (runWith) runWith();
    if (canPlayAnimation() || !runAfter) return;
    runAfter();
  }, []);

  return (
    <>
      {canPlayAnimation() &&
        cards.map((card, index) =>
          movingCard(card, cardsDelays[index], index === cards.length - 1 ? handleMove : undefined)
        )}
    </>
  );
};
