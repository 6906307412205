import React from 'react';
import { UseHuntEssenceAction } from './useHuntEssenceAction.types';
import { EssenceUpdateType, GameContext } from '../../../gameContext';
import { LocationTrackerContext } from '../../../locationTrackerContextProvider';
import { AnimationContext } from '../../../animationContextProvider';
import { getCurrentPlayer } from '../../../gameContextHandler/utils/player';
import { CANCELED, DEFAULT_DRAW_CARD_AMOUNT, FINISHED, STARTED } from '../../../gameTypes';
import { PlayingCard } from '../../../../card/cardTypes';
import { roomItems } from '../../../../room/hooks/useTrackLocation';
import { filterUndefined } from '../../../../../helpers/arrays';
import { useChangeEssenceCount } from '../../../gameContextHandler/hooks/useChangeEssenceCount';
import { addToDiscardPile, removeCardFromPlayer } from '../../gameStateOperations';
import { openCards } from '../../../../card/cardTransformers';
import { Log, LogContext } from '../../../logContextProvider';
import { useSoundEffects } from '../useSoundEffects';

export const useHuntEssenceAction: UseHuntEssenceAction = () => {
  const { gameState, withUpdatingState, addTurnAction, finishTurn } = React.useContext(GameContext);
  const { createLog, setNotification } = React.useContext(LogContext);
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { queueMovingCardsProps } = React.useContext(AnimationContext);
  const { changeEssenceCount } = useChangeEssenceCount({ gameState: gameState, addTurnAction: addTurnAction });
  const { applyDiscardCardSound } = useSoundEffects({ gameState: gameState });

  const huntEssence = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    void setNotification({ type: 'warning', text: 'Exchange 1 essence for 2 cards: click on Deck' });
    addTurnAction({ player: currentPlayer.id, action: `essence hunt ${STARTED}` });
    gameState.drawCardAmount = 0;
  };

  const completeHuntEssence = (cards: PlayingCard[]) => {
    const currentPlayer = getCurrentPlayer(gameState);

    const curPlayerLocation = getItemLocation(roomItems.playerHand(currentPlayer.id));
    const stops = filterUndefined([curPlayerLocation, getItemLocation(roomItems.discardPile)]);

    const runAfterAnimation = withUpdatingState(() => {
      const log: Log = {
        type: 'warning',
        text: `${currentPlayer.playerName}: essence hunt finished after discarding ${cards.length} cards`,
      };

      addTurnAction({
        player: currentPlayer.id,
        action: `essence hunt ${FINISHED}`,
        cardIds: cards.map((card) => card.id),
        logs: [log],
      });
      changeEssenceCount(currentPlayer.id, EssenceUpdateType.ADD_ONE);
      cards.forEach((card) => {
        addToDiscardPile(card, gameState);
        removeCardFromPlayer(card, currentPlayer.id, gameState);
      });

      void createLog(log);
      finishTurn(false);
    });

    queueMovingCardsProps([
      { stops: stops, cards: openCards(cards), runAfter: runAfterAnimation, runWith: applyDiscardCardSound },
    ]);
  };

  const cancelHuntEssence = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    addTurnAction({ player: currentPlayer.id, action: `essence hunt ${CANCELED}` });
    gameState.drawCardAmount = DEFAULT_DRAW_CARD_AMOUNT;
  };

  return {
    huntEssence: withUpdatingState(huntEssence),
    completeHuntEssence: completeHuntEssence,
    cancelHuntEssence: withUpdatingState(cancelHuntEssence),
  };
};
