import { UseRemovePlayerFromGame } from './useRemovePlayerFromGame.types';
import { isEmpty, removeElement } from '../../../../../helpers/arrays';
import { Player } from '../../../../player/player.types';
import { GroupedCards, PlayerCardGroup } from '../../gameContextHandler.types';
import React from 'react';
import { Log, LogContext } from '../../../logContextProvider';

export const useRemovePlayerFromGame: UseRemovePlayerFromGame = ({
  gameState,
  performFinishDefending,
  finishTurn,
  addTurnAction,
  forceDefendingFinish,
}) => {
  const { createLog } = React.useContext(LogContext);

  const addLostUponRemovalActions = (playerId: string, groupedCards: GroupedCards) => {
    const groupedCardIds = new Map<PlayerCardGroup, string[]>();

    for (const [group, cards] of groupedCards.entries()) {
      if (isEmpty(cards)) continue;
      const cardIds = cards.map((card) => card.id);
      groupedCardIds.set(group, cardIds);
    }

    addTurnAction({
      player: playerId,
      action: 'lost cards upon removal',
      appliedTo: playerId,
      groupedCardIds: Array.from(groupedCardIds.entries()),
    });
  };

  const removePlayerFromGame = (playerToUpdate: Player) => {
    if (playerToUpdate.id === gameState.activeDefendingPlayer) {
      performFinishDefending('withEssenceLoss');
    }

    const playerCardsInHand = playerToUpdate.cardsInHand;
    const playerCardsOnTable = playerToUpdate.cardsOnTable;
    const playersActiveRadiances = playerToUpdate.activeRadiances;

    addLostUponRemovalActions(
      playerToUpdate.id,
      new Map([
        ['cardsInHand', playerCardsInHand],
        ['cardsOnTable', playerCardsOnTable],
        ['activeRadiances', playersActiveRadiances],
      ])
    );

    playerToUpdate.roleVisibleTo.push(...gameState.allPlayers.map((player) => player.id));
    gameState.cardsInDiscardPile.push(...playerCardsInHand, ...playerCardsOnTable, ...playersActiveRadiances);
    playerToUpdate.cardsInHand = [];
    playerToUpdate.cardsOnTable = [];
    playerToUpdate.activeRadiances = [];

    const logs: Log[] = [];
    const leaveGameLog: Log = { type: 'error', text: `${[playerToUpdate.playerName]}: Deltaration left` };
    logs.push(leaveGameLog);

    if (gameState.playersOrdered.length === 3) {
      const minusEssenceLog: Log = { type: 'error', text: `Each player starts to lose 1 essence at turn start` };
      logs.push(minusEssenceLog);
      void createLog(minusEssenceLog);
    }

    addTurnAction({ player: playerToUpdate.id, action: 'create log', logs: logs });
    void createLog(leaveGameLog);

    if (playerToUpdate.id === gameState.activePlayer) {
      forceDefendingFinish();
      finishTurn(true);
    }

    removeElement(gameState.playersOrdered, (player) => player === playerToUpdate.id);
  };

  return {
    removePlayerFromGame: removePlayerFromGame,
  };
};
