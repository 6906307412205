import axios from 'axios';
import { DELTARATION_PREDICTION_TARGETS_URL } from '../../constants/delarationApi';
import { ApiAuthRequest, PredictionTargetsApiData } from './predictionTypes';
import { parseProfile } from '../../helpers/player';
import { IGNORE_GAME_BLOCK_ON_ERROR_HEADER } from '../../config/axios';

class PredictionApi {
  private withAuthorization(headers: object) {
    return { ...headers, Authorization: `Bearer ${parseProfile()?.appToken}` };
  }

  public async getPredictionTargets(gameId: string, authRequest: ApiAuthRequest): Promise<PredictionTargetsApiData> {
    const response = await axios.get(DELTARATION_PREDICTION_TARGETS_URL(gameId), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
        [IGNORE_GAME_BLOCK_ON_ERROR_HEADER]: true,
        ...authRequest,
      }),
    });

    return response.data;
  }
}

export default PredictionApi;
