export type Card = {
  cardType: CardType;
  image: string;
  details: string;
  isOpen: boolean;
};

export type PlayingCard = Card & {
  id: string;
  playingCardType: PlayingCardType;
  applyRule: ApplyRule;
  category: CardCategory;
  energyColors: EnergyColors[];
  name: CardNames;
  allowedApplyTypes: AllowedApplyType[];
  gameModes: string[];
};

export enum AllowedApplyType {
  INACTIVE = 'Inactive',
  PLAYING_AREA = 'Playing Area',
  OTHER_PLAYER = 'Other Player',
  ARSENAL = 'Arsenal',
  ENERGY = 'Energy',
  ON_TELEPORT = 'On Teleport',
  FOR_ESSENCE_HUNT = 'For Essence hunt',
}

export const COMBAT_ALLOWED_APPLY_TYPES: AllowedApplyType[] = [
  AllowedApplyType.PLAYING_AREA,
  AllowedApplyType.OTHER_PLAYER,
];

export const ACTIVE_ALLOWED_APPLY_TYPES: AllowedApplyType[] = [
  AllowedApplyType.PLAYING_AREA,
  AllowedApplyType.OTHER_PLAYER,
  AllowedApplyType.ARSENAL,
  AllowedApplyType.ENERGY,
  AllowedApplyType.ON_TELEPORT,
  AllowedApplyType.FOR_ESSENCE_HUNT,
];

export enum PlayingCardType {
  ARSENAL = 'Arsenal',
  NEBULA = 'Nebula',
  RADIANCE = 'Radiance',
  LIQUID = 'Liquid',
}

export const ENERGY_DEPENDENT_CARD_TYPES: PlayingCardType[] = [PlayingCardType.RADIANCE, PlayingCardType.NEBULA];

export enum ApplyRule {
  ALL,
  ALL_BUT_ME,
  OTHER_PLAYER,
  ANY_PLAYER,
  ME,
  GAUNTLET,
  SHELL,
  DOME,
  BACKPACK,
  ENERGY,
}

export enum ApplyRuleGroup {
  ALL,
  ARSENAL,
  PLAYING_AREA,
}

export const allApplyRules = [
  ApplyRule.ME,
  ApplyRule.ALL_BUT_ME,
  ApplyRule.OTHER_PLAYER,
  ApplyRule.ANY_PLAYER,
  ApplyRule.ALL,
  ApplyRule.GAUNTLET,
  ApplyRule.SHELL,
  ApplyRule.BACKPACK,
  ApplyRule.DOME,
  ApplyRule.ENERGY,
];

export type RoleCard = Card & {
  role: Role;
  description: string;
};

export enum CardType {
  ROLE,
  PLAYING_CARD,
}

export enum Role {
  IMMUNAR = 'Immunar',
  DELTARED = 'Deltared',
  ORTEGONIST = 'Ortegonist',
}

export enum EnergyColors {
  RED = 'red',
  BLACK = 'black',
  YELLOW = 'yellow',
  BLUE = 'blue',
}

export type ShellColor = 'RED_BLACK' | 'YELLOW_BLUE';
export type DtoDescription = { value: string };
export type PlayingCardDtoType = 'ARSENAL' | 'NEBULA' | 'RADIANCE' | 'LIQUID';
export type EnergyColorDto = 'YELLOW' | 'BLUE' | 'RED' | 'BLACK';
export type CardNameDto =
  | 'GAUNTLET'
  | 'SHELL'
  | 'BACKPACK'
  | 'DOME'
  | 'TELEPORT'
  | 'DE_ESSENCER'
  | 'BLACK_HOLE'
  | 'SPY'
  | 'MASS_DE_ESSENCER'
  | 'ESSENCE_THIEF'
  | 'CHALLENGE'
  | 'SPACE_DISTORTION'
  | 'DISINTEGRATOR'
  | 'DISORIENTATION'
  | 'SQUANDER'
  | 'ESSENCE_ENCAPSULATION'
  | 'EQUALIZER'
  | 'MASS_EQUALIZER'
  | 'UNCHAINED_ESSENCE'
  | 'MASS_ESSENCE_ESCAPE'
  | 'FOGGER'
  | 'AMNESIA'
  | 'ENERGY'
  | 'REVEALER'
  | 'CONVERSIONER';

export type ApplyRuleDtoType =
  | 'ME'
  | 'OTHER_PLAYER'
  | 'ANY_PLAYER'
  | 'ALL'
  | 'ALL_BUT_ME'
  | 'GAUNTLET'
  | 'SHELL'
  | 'DOME'
  | 'BACKPACK'
  | 'ENERGY';

export type CardCategory = 'ATTACKING' | 'DEFENDING' | 'OTHER';

export type PlayingCardDto = {
  type: PlayingCardDtoType;
  colors: EnergyColorDto[];
  name: CardNameDto;
  applyRule: ApplyRuleDtoType;
  category: CardCategory;
  //TODO maybe change the way we pass value on back
  onCardDescription: DtoDescription;
  active: boolean;
  totalCount: number;
  gameModes: string[];
  itemIds: string[];
};

export enum CardNames {
  GAUNTLET = 'Gauntlet',
  SHELL = 'Shell',
  BACKPACK = 'Backpack',
  DOME = 'Dome',
  TELEPORT = 'Teleport',
  DE_ESSENCER = 'De-essencer',
  BLACK_HOLE = 'Black hole',
  SPY = 'Spy',
  MASS_DE_ESSENCER = 'Mass de-essencer',
  ESSENCE_THIEF = 'Essence thief',
  CHALLENGE = 'Challenge',
  SPACE_DISTORTION = 'Space distortion',
  DISINTEGRATOR = 'Disintegrator',
  DISORIENTATION = 'Disorientation',
  SQUANDER = 'Squander',
  ESSENCE_ENCAPSULATION = 'Essence encapsulation',
  EQUALIZER = 'Equalizer',
  MASS_EQUALIZER = 'Mass Equalizer',
  UNCHAINED_ESSENCE = 'Unchained essence',
  MASS_ESSENCE_ESCAPE = 'Mass essence escape',
  FOGGER = 'Fogger',
  AMNESIA = 'Amnesia',
  ENERGY = 'Energy',
  REVEALER = 'Revealer',
  CONVERSIONER = 'Conversioner',
}

export const MASS_ACTION_CARDS: CardNames[] = [CardNames.MASS_DE_ESSENCER, CardNames.MASS_EQUALIZER];
export const DE_ESSENCER_CARDS: CardNames[] = [
  CardNames.DE_ESSENCER,
  CardNames.MASS_DE_ESSENCER,
  CardNames.ESSENCE_THIEF,
];
export const EQUALIZER_CARDS: CardNames[] = [CardNames.EQUALIZER, CardNames.MASS_EQUALIZER];

export const INDIVIDUAL_ESSENCE_LOSS_CARDS: CardNames[] = [
  CardNames.DE_ESSENCER,
  CardNames.EQUALIZER,
  CardNames.ESSENCE_THIEF,
  CardNames.CHALLENGE,
];

export const ESSENCE_LOSS_CARDS: CardNames[] = [...INDIVIDUAL_ESSENCE_LOSS_CARDS, ...MASS_ACTION_CARDS];
export const ESSENCE_GAIN_CARDS: CardNames[] = [CardNames.UNCHAINED_ESSENCE, CardNames.MASS_ESSENCE_ESCAPE];

export const ATTACKING_NEBULA_CARDS: CardNames[] = [
  ...DE_ESSENCER_CARDS,
  CardNames.CHALLENGE,
  CardNames.SPY,
  CardNames.TELEPORT,
  CardNames.DISINTEGRATOR,
  CardNames.CONVERSIONER,
];

export const RADIANCE_CARDS: CardNames[] = [CardNames.DISORIENTATION, CardNames.SQUANDER];

export const DEFENDING_NEBULA_CARDS: CardNames[] = [CardNames.BLACK_HOLE, CardNames.SPACE_DISTORTION];

export const ATTACKING_CARDS: CardNames[] = [
  ...ATTACKING_NEBULA_CARDS,
  ...EQUALIZER_CARDS,
  ...RADIANCE_CARDS,
  CardNames.REVEALER,
];

export const PROBABILITY_MANIPULATION_CARDS: CardNames[] = [CardNames.FOGGER, CardNames.AMNESIA];
