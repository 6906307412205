import React from 'react';
import { UseChallengeEffect } from './useChallengeEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toInProgressAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { filterIgnoredDefendingActions, isDomeCheckInProgress } from '../../../../game/rules/rules';
import { useProbabilityRollAction } from '../../../../game/actions/hooks/useProbabilityRollAction';
import { LogContext } from '../../../../game/logContextProvider';

export const useChallengeEffect: UseChallengeEffect = () => {
  const { gameState } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);
  const { rollProbCapsule } = useProbabilityRollAction();

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastAttackingAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    const lastTurnAction = last(gameState.turnActions);

    if (
      currentPlayer.id !== gameState.activeDefendingPlayer ||
      lastAttackingAction !== toInProgressAction(CardNames.CHALLENGE) ||
      gameState.activeDefendingPlayer === gameState.probRollAllowedFor ||
      isDomeCheckInProgress(gameState) ||
      (lastTurnAction !== undefined &&
        lastTurnAction.action === 'probability capsule randomized' &&
        lastTurnAction.player === gameState.activeDefendingPlayer)
    ) {
      return;
    }

    rollProbCapsule();
    void setNotification({
      type: 'warning',
      text: 'Probability capsule was randomized for Challenge',
    });
  };
};
