import React from 'react';
import { Props } from './droppableRadiance.types';
import { GameContext } from '../../../game/gameContext';
import { DraggedCardData } from '../../../../components/draggable';
import { canTeleportRadiance } from '../../../game/rules/rules';
import { useMoveCardAction } from '../../../game/actions/hooks/useMoveCardAction';
import { Droppable } from '../droppable';
import { ApplyRuleGroup } from '../../../card/cardTypes';

export const DroppableRadiance: React.FC<Props> = ({ playerId, children }) => {
  const { gameState } = React.useContext(GameContext);
  const { moveCardBetweenPlayers } = useMoveCardAction();

  const onDropItem = (item: DraggedCardData) => {
    if (!item.fromPlayerId) return;

    if (canTeleportRadiance(item, gameState.turnActions)) {
      moveCardBetweenPlayers(playerId, item.fromPlayerId, playerId, item.cards[0], 'activeRadiances');
      return;
    }
  };

  const canDropItem = (item: DraggedCardData) => canTeleportRadiance(item, gameState.turnActions);

  return (
    <Droppable playerId={playerId} applyRuleGroup={ApplyRuleGroup.ALL} onItemDropped={onDropItem} canDrop={canDropItem}>
      {children}
    </Droppable>
  );
};
