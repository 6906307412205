import React from 'react';
import { Box, Card as MuiCard, CardContent, Typography } from '@mui/material';
import { sx } from './preGameBlock.styles';
import { PreGameCard, Props } from './preGameBlock.type';
import Typed from 'react-typed';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { TEST_PLAYING_CARDS_CLOSED } from '../../constants/demoData';
import { Logo } from '../logo/logo';
import { getBackgroundBoxSx } from '../../helpers/styles';
import { AppContext } from '../../global/context/appContext';
import { MotionBlock } from '../motionBlock';
import { CardGroup } from '../cardGroup';
import Button from '@mui/material/Button';
import { ContentCopy } from '@mui/icons-material';
import { GameWidgetsPanel, MobileGameWidgetsPanel } from '../../features/menu/widgetsPanel';
import { LogContext } from '../../features/game/logContextProvider';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { CardType } from '../../features/card/cardTypes';

export const PreGameBlock: React.FC<Props> = ({
  gameId,
  playerDataList = [],
  numberOfPlayers = undefined,
  notification,
}) => {
  const { story, windowSize, isMobile } = React.useContext(AppContext);
  const { setNotification } = React.useContext(LogContext);

  const [playerName, setPlayerName] = React.useState<string>();
  const onCardHoverIn = (card: PreGameCard) => setPlayerName(card.playerName);
  const onCardHoverOut = () => setPlayerName(undefined);

  const onCardClickMobile = (card: PreGameCard) => {
    setPlayerName((prevState) => (prevState === card.playerName ? undefined : card.playerName));
  };

  const size = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];

  let gameBlock = <></>;

  if (numberOfPlayers !== undefined && playerDataList !== undefined) {
    const joinedPlayerCards = TEST_PLAYING_CARDS_CLOSED.slice(0, playerDataList.length).map((card, index) => ({
      ...card,
      isOpen: false,
      cardType: CardType.ROLE,
      playerName: playerDataList[index].name,
    }));

    gameBlock = (
      <Box sx={sx.mainBox}>
        <Box sx={sx.playersText}>
          <Typography>{playerName ?? 'Players'}</Typography>
        </Box>
        <Box sx={sx.cardGroupInnerBox}>
          <CardGroup
            isActive={false}
            cards={joinedPlayerCards}
            size={size}
            placeholdersCount={numberOfPlayers}
            onCardMouseEnter={isMobile ? undefined : onCardHoverIn}
            onCardMouseLeave={isMobile ? undefined : onCardHoverOut}
            onCardClick={isMobile ? onCardClickMobile : undefined}
          />
        </Box>
      </Box>
    );
  }

  const copyToClipboard = async (textToCopy: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
      void setNotification({ type: 'success', text: 'Copied to clipboard' });
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';
    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
      void setNotification({ type: 'success', text: 'Copied to clipboard' });
    } catch (error) {
      void setNotification({
        type: 'error',
        text: 'Auto copy did not work. Please copy the value manually',
      });
    } finally {
      textArea.remove();
    }
  };

  const handleCopyGameIdClick = () => {
    void copyToClipboard(gameId);
  };

  return (
    <Box sx={{ ...getBackgroundBoxSx(windowSize, isMobile), pt: 6, px: 1 }}>
      {isMobile ? <MobileGameWidgetsPanel /> : <GameWidgetsPanel />}
      <MotionBlock transitionDuration={1}>{gameBlock}</MotionBlock>
      <MotionBlock transitionDuration={1}>
        <Box sx={sx.notificationBox}>
          <MuiCard sx={sx.cardBox}>
            <CardContent>
              <Typography>{notification}</Typography>
              {gameId !== '' && (
                <Button sx={sx.gameIdBox} onClick={handleCopyGameIdClick}>
                  <CardContent sx={sx.cardNotification}>
                    <Typography>{gameId}</Typography>
                  </CardContent>
                  <ContentCopy></ContentCopy>
                </Button>
              )}
            </CardContent>
          </MuiCard>
        </Box>
      </MotionBlock>
      <MotionBlock transitionDuration={2.5}>
        <Logo withLoader={true} />
        <Box sx={sx.storyBox}>
          <Typed strings={story} startDelay={2600} typeSpeed={8} contentType={'html'} fadeOut={true} />
        </Box>
      </MotionBlock>
    </Box>
  );
};
