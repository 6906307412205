import React from 'react';
import styles from './cardMovementDrawer.module.css';
import { useWindowSize } from '../../../helpers/utils';
import { Location } from '../../../features/game/locationTrackerContextProvider';
import { ProjectColors } from '../../../themes/mainTheme';
import { SECOND_IN_MILLISECONDS } from '../../../constants/time';
import { Props } from './cardMovementDrawer.types';

export const CardMovementDrawer: React.FC<Props> = ({ stops, cards }) => {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const { width, height } = useWindowSize();

  let shadowColor = ProjectColors.WHITE;

  if (cards.some((card) => card.isOpen && card.category === 'ATTACKING')) {
    shadowColor = ProjectColors.RED;
  }
  if (cards.some((card) => card.isOpen && card.category === 'DEFENDING')) {
    shadowColor = ProjectColors.BLUE;
  }

  React.useEffect(() => {
    if (!canvasRef.current) return;

    const context = canvasRef.current.getContext('2d');
    if (!context) return;

    const drawLine = (from: Location, to: Location) => {
      context.beginPath();
      context.moveTo(from.x, from.y);
      context.lineTo(to.x, to.y);
      context.strokeStyle = `${ProjectColors.TRANSPARENT_WHITE}`;
      context.lineWidth = 20;
      context.lineCap = 'round';
      context.lineJoin = 'round';
      context.filter = 'blur(6px)';
      context.shadowBlur = 5;
      context.shadowColor = `${shadowColor}`;
      context.stroke();
    };

    const clearCanvas = () => {
      if (!canvasRef.current) return;
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    let totalAnimationTime = 0;
    const totalDurationInMs = 4 * SECOND_IN_MILLISECONDS;

    stops.forEach((_, index) => {
      if (index < stops.length - 1) {
        const from = stops[index];
        const to = stops[index + 1];
        setTimeout(() => drawLine(from, to), totalAnimationTime);
        totalAnimationTime += totalDurationInMs / stops.length;
      }
    });

    setTimeout(() => clearCanvas(), totalDurationInMs + 12 * SECOND_IN_MILLISECONDS);
  }, [stops]);

  return <canvas ref={canvasRef} width={width} height={height} className={styles.fadeCanvas} />;
};
