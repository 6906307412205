import React from 'react';
import styles from './notificationItem.module.css';
import cn from 'classnames';
import { Props } from './notificationItem.types';
import { SECOND_IN_MILLISECONDS } from '../../../../../../constants/time';

export const NotificationItem: React.FC<Props> = ({ notification, runAfter, delay = 0.1 }) => {
  const [showNotification, setShowNotification] = React.useState(true);
  const [animationState, setAnimationState] = React.useState('hidden');

  React.useEffect(() => {
    const enterTimeout = setTimeout(() => {
      setAnimationState('visible');
    }, delay * SECOND_IN_MILLISECONDS);

    const exitTimeout = setTimeout(() => {
      setAnimationState('exiting');
      setTimeout(() => {
        setShowNotification(false);
        if (runAfter) runAfter();
      }, 0.4 * SECOND_IN_MILLISECONDS);
    }, (3.2 + delay) * SECOND_IN_MILLISECONDS);

    return () => {
      clearTimeout(enterTimeout);
      clearTimeout(exitTimeout);
    };
  }, [delay, runAfter]);

  if (!showNotification) return null;

  return (
    <div className={cn(styles.tickerItemContainer, styles[notification.type], styles[animationState])}>
      {notification.text}
    </div>
  );
};
