import React from 'react';
import { DraggedCardData, DragSource } from '../../../../../components/draggable';
import { UseDiscardPileActionType } from './useDiscardPileAction.types';
import { canDisintegrate } from '../../../rules/rules';
import { GameContext } from '../../../gameContext';
import { addToDiscardPile, removeCardFromPlayer, removeFromPlayingArea } from '../../gameStateOperations';
import { getCurrentPlayer } from '../../../gameContextHandler/utils/player';
import { TurnAction } from '../../../gameTypes';
import { PlayerCardGroup } from '../../../gameContextHandler';
import { Log, LogContext } from '../../../logContextProvider';
import { hasOneElement, removeRandomElements } from '../../../../../helpers/arrays';
import { PlayingCard } from '../../../../card/cardTypes';
import { useSoundEffects } from '../useSoundEffects';
import { Player } from '../../../../player/player.types';

export const useDiscardPileAction: UseDiscardPileActionType = () => {
  const { gameState, addTurnAction, finishDefending, enableDragAndDrop, withUpdatingState } =
    React.useContext(GameContext);
  const { createLog, setNotification } = React.useContext(LogContext);
  const { applyDiscardCardSound } = useSoundEffects({ gameState: gameState });

  const moveCardToDiscardPile = (item: DraggedCardData) => {
    const turnActionToAdd: TurnAction = { player: gameState.activePlayer, action: 'cardsDiscarded' };
    const isDisintegrationPossible = canDisintegrate(gameState.turnActions);
    if (isDisintegrationPossible) {
      if (!hasOneElement(item.cards)) {
        void setNotification({
          type: 'warning',
          text: `You can only disintegrate one card at a time. Only one card will be disintegrated`,
        });

        item.cards = [item.cards[0]];
      }
      if (gameState.activeDefendingPlayer) {
        turnActionToAdd.appliedTo = gameState.activeDefendingPlayer;
        finishDefending();
      }
    }

    turnActionToAdd.cardIds = item.cards.map((card) => {
      addToDiscardPile(card, gameState);
      return card.id;
    });

    const playerId = item.fromPlayerId;
    switch (item.dragSource) {
      case DragSource.APPLIED_RADIANCE:
        if (playerId && isDisintegrationPossible) {
          const cardsToGroupMap = removeFromPlayerWithLog(playerId, item, turnActionToAdd);
          const group = cardsToGroupMap.get(item.cards[0].id);
          if (group) turnActionToAdd.action = `disintegrated from ${group}`;
        }
        break;
      case DragSource.PLAYER:
        if (playerId) {
          const cardsToGroupMap = removeFromPlayerWithLog(playerId, item, turnActionToAdd);
          const group = cardsToGroupMap.get(item.cards[0].id);
          if (group && isDisintegrationPossible) turnActionToAdd.action = `disintegrated from ${group}`;
        }
        break;
      case DragSource.PLAYING_AREA:
        item.cards.map((card) => removeFromPlayingArea(card, gameState, enableDragAndDrop));
        break;
    }

    applyDiscardCardSound();
    addTurnAction(turnActionToAdd);
  };

  const removeFromPlayerWithLog = (
    playerId: string,
    item: DraggedCardData,
    turnActionToAdd: TurnAction
  ): Map<string, PlayerCardGroup> => {
    const cardToGroupMap = new Map<string, PlayerCardGroup>();
    let removedFromGroup: PlayerCardGroup | undefined;

    item.cards.forEach((card) => {
      removedFromGroup = removeCardFromPlayer(card, playerId, gameState);
      if (removedFromGroup) cardToGroupMap.set(card.id, removedFromGroup);
    });

    const log: Log = { type: 'info', text: prepareDiscardLogMessage(playerId, item.cards, removedFromGroup) };
    turnActionToAdd.logs = [log];
    void createLog(log);

    return cardToGroupMap;
  };

  const prepareDiscardLogMessage = (
    playerId: string,
    cards: PlayingCard[],
    group: PlayerCardGroup | undefined
  ): string => {
    const currentPlayer = getCurrentPlayer(gameState);
    const items = hasOneElement(cards) && group !== 'cardsInHand' ? `'${cards[0].name}'` : `${cards.length} card(s)`;
    const baseMessage = `${currentPlayer.playerName}: ${items} discarded`;
    if (currentPlayer.id === playerId) {
      return baseMessage;
    }

    const playerName = gameState.allPlayers.find((player) => player.id === playerId)?.playerName;
    return playerName ? baseMessage + ` from player '${playerName}'` : baseMessage;
  };

  const discardPlayerRandomCards = (player: Player, number: number): void => {
    const randomHandCards = removeRandomElements(player.cardsInHand, number);

    const turnActionToAdd: TurnAction = { player: gameState.activePlayer, action: 'cardsDiscarded', cardIds: [] };

    randomHandCards.forEach((card) => {
      addToDiscardPile(card, gameState);
      removeCardFromPlayer(card, player.id, gameState);
      turnActionToAdd.cardIds?.push(card.id);
    });

    applyDiscardCardSound();
    addTurnAction(turnActionToAdd);
  };

  return {
    moveCardToDiscardPile: withUpdatingState(moveCardToDiscardPile),
    discardPlayerRandomCards: discardPlayerRandomCards,
  };
};
