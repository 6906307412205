import React from 'react';
import { GameContext } from '../../../../game/gameContext';
import { UseCardDrop } from './useCardDrop.types';
import { DraggedCardData } from '../../../../../components/draggable';
import {
  canDropDraggedCardToHand,
  canTeleport,
  canTeleportRadiance,
  runIfArsenalCardCanBeApplied,
} from '../../../../game/rules/rules';
import { AllowedApplyType, PlayingCardType } from '../../../../card/cardTypes';
import { useMoveCardAction } from '../../../../game/actions/hooks/useMoveCardAction';
import { LogContext } from '../../../../game/logContextProvider';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';

export const useCardDrop: UseCardDrop = ({ playerId, cardsOnTable }) => {
  const { gameState } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);
  const { moveCardBetweenPlayers } = useMoveCardAction();

  const onDropToHand = (item: DraggedCardData) => {
    if (!item.fromPlayerId) return;
    moveCardBetweenPlayers(getCurrentPlayer(gameState).id, item.fromPlayerId, playerId, item.cards[0], 'cardsInHand');
  };

  const onDropToArsenal = (item: DraggedCardData) => {
    try {
      runIfArsenalCardCanBeApplied(playerId, item.cards[0], cardsOnTable, gameState.turnActions, () => {
        if (!item.fromPlayerId) return;
        moveCardBetweenPlayers(
          getCurrentPlayer(gameState).id,
          item.fromPlayerId,
          playerId,
          item.cards[0],
          'cardsOnTable'
        );
      });
    } catch (e: any) {
      void setNotification({ type: 'error', text: e.message });
    }
  };

  const canDropToHand = (item: DraggedCardData) =>
    canDropDraggedCardToHand(item, playerId, gameState) && !canTeleportRadiance(item, gameState.turnActions);

  const canDropToArsenal = (item: DraggedCardData) =>
    canDropToHand(item) &&
    (item.cards[0].allowedApplyTypes.includes(AllowedApplyType.ARSENAL) ||
      (canTeleport(gameState.turnActions) &&
        item.cards[0].isOpen &&
        item.cards[0].playingCardType === PlayingCardType.ARSENAL));

  return {
    canDropToHand: canDropToHand,
    canDropToArsenal: canDropToArsenal,
    onDropToHand: onDropToHand,
    onDropToArsenal: onDropToArsenal,
  };
};
