import React from 'react';
import styles from './notificationTicker.module.css';
import { isFlagOn } from '../../../../../helpers/featureFlags';
import { DISABLE_NOTIFICATIONS_FLAG } from '../../../../../constants/featureFlags';
import { Log, LogContext } from '../../../../game/logContextProvider';
import { isEmpty } from '../../../../../helpers/arrays';
import { NotificationItem } from './notificationItem';
import { Props } from './notificationTicker.types';
import cn from 'classnames';

export const NotificationTicker: React.FC<Props> = ({ variant }) => {
  const { logWorker } = React.useContext(LogContext);

  const [notificationQueue, setNotificationQueue] = React.useState<Log[]>([]);
  const [notifications, setNotifications] = React.useState<React.ReactElement>();

  const addNotificationToQueue = (log: Log) => setNotificationQueue((prevQueue) => [...prevQueue, log]);

  React.useEffect(() => {
    if (isEmpty(notificationQueue) || notifications) return;

    const tickerItems = Array.from({ length: notificationQueue.length }, (_, index) => (
      <NotificationItem
        key={'notification-' + index}
        notification={notificationQueue[index]}
        delay={notificationQueue.length > 1 && index > 0 ? 2.8 : undefined}
        runAfter={index === notificationQueue.length - 1 ? () => setNotifications(undefined) : undefined}
      />
    ));

    setNotifications(<>{tickerItems}</>);
    setNotificationQueue([]);
  }, [notifications, notificationQueue.length]);

  if (logWorker && !isFlagOn(DISABLE_NOTIFICATIONS_FLAG)) {
    logWorker.onmessage = (e: MessageEvent<Log>) => {
      const log = e.data;
      addNotificationToQueue(log);
    };
  }

  return <div className={cn(styles.outerContainer, styles[variant])}>{notifications}</div>;
};
