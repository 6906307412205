import React, { type JSX } from 'react';
import { Props } from './currentPlayerMobile.types';
import { GameContext } from '../../../game/gameContext';
import { useCardDimension } from '../../../../components/card/hooks/useCardDimension';
import { Box, SxProps } from '@mui/material';
import { getPlayerBoxSxFromState } from '../../../../helpers/styles';
import { EssenceBar } from '../../../../components/essenceBar';
import { FinishActionButton } from '../../../../components/finishActionButton';
import { NORMAL_TO_EXTENDED_SIZE, SizeNormal } from '../../../../helpers/sizes';
import { sxInit } from './currentPlayerMobile.styles';
import { DisplayMode } from '../../player.types';
import { AppContext } from '../../../../global/context/appContext';
import { ActionDrawerContext } from '../../../room/mobile/contexts/actionDrawerContext/actionDrawerContext';
import { CardLContent } from '../../../room/mobile/components/actionDrawer/cardLContent';
import { ArsenalDropPoint } from './arsenalDropPoint';
import { Hand } from './hand';
import { Role } from './role';
import { getCurrentPlayer } from '../../../game/gameContextHandler/utils/player';
import { BlocksDirection, definePlayerStateByGameState } from '../../../../helpers/player';
import { PlayerMobile } from '../../mobile/playerMobile';
import { DroppableRadiance } from '../../droppable/droppableRadiance';

export const CurrentPlayerMobile: React.FC<Props> = () => {
  const { windowSize } = React.useContext(AppContext);
  const { gameState } = React.useContext(GameContext);
  const { setContent, setName } = React.useContext(ActionDrawerContext);

  const wrapInBox = (element: JSX.Element, styles: SxProps) => <Box sx={styles}>{element}</Box>;
  const wrapInCapsuleBox = (element: JSX.Element) => wrapInBox(element, sx.capsuleBox);

  const currentPlayer = getCurrentPlayer(gameState);
  const onProfileShow = () => {
    setContent(
      <Box>
        <CardLContent card={currentPlayer.roleCard} />
      </Box>
    );
    setName(`${currentPlayer.playerName}'s role`);
  };

  const size = SizeNormal.XS;
  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];

  const { dimension: cardDimension } = useCardDimension({ size: extendedSize });
  const sx = sxInit(cardDimension, windowSize.width);
  const playerState = definePlayerStateByGameState(currentPlayer.id, gameState);

  return (
    <Box sx={sx.outerBox}>
      <Box
        key={'current-player-mobile-animated-box' + currentPlayer.id + playerState}
        sx={{
          ...getPlayerBoxSxFromState(playerState, DisplayMode.MOBILE),
          ...sx.stateBox,
        }}
      />
      <Box key={'current-player-mobile-box-' + currentPlayer.id} sx={sx.innerBox}>
        <Box sx={sx.rowsBox}>
          <DroppableRadiance playerId={currentPlayer.id}>
            <Box sx={sx.bottomRow}>
              <Box component="div" sx={sx.profile}>
                <PlayerMobile
                  player={currentPlayer}
                  blocksDirection={BlocksDirection.LEFT_TO_RIGHT}
                  roleComponent={
                    <ArsenalDropPoint player={currentPlayer}>
                      <Role size={extendedSize} onProfileShow={onProfileShow} />
                    </ArsenalDropPoint>
                  }
                  isDraggable
                />
              </Box>
              <Hand size={extendedSize} />
              <Box sx={sx.button}>
                {wrapInCapsuleBox(<EssenceBar playerId={currentPlayer.id} count={currentPlayer.essences} />)}
                <FinishActionButton displayMode={DisplayMode.MOBILE} />
              </Box>
            </Box>
          </DroppableRadiance>
        </Box>
      </Box>
    </Box>
  );
};
