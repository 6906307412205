import { TYPOGRAPHY_SHADOW_SX } from '../../constants/styles';

export const customSx = {
  compactGridCard: { width: '50%', display: 'flex', justifyContent: 'center', cursor: 'pointer', zIndex: 1 },
  gridCard: { minWidth: 'auto', cursor: 'pointer', zIndex: 1 },
  placeholder: { minWidth: 'auto' },
  labelTypography: {
    mb: '0.5%',
    lineHeight: 1,
    fontSize: '0.95rem',
    ...TYPOGRAPHY_SHADOW_SX,
  },
  mainBox: { width: '100%' },
};
