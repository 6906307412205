import React from 'react';
import {
  NO_SKIP_PLAYER_NOTIFICATION_PING_COUNT,
  AUTO_SET_PLAYER_IDLE_SKIP_COUNT,
  UsePingIdlePlayer,
} from './usePingIdlePlayer.types';
import { getAttackingPlayerId, getCurrentPlayer, getPlayer } from '../../../gameContextHandler/utils/player';
import { LogContext, LogType } from '../../../logContextProvider';
import { useSoundEffects } from '../useSoundEffects';
import { SoundPath } from '../../../../../assets/assets';
import { GameState } from '../../../gameContextHandler/hooks/useGameState';
import { GameContext } from '../../../gameContext';
import { SECOND_IN_MILLISECONDS } from '../../../../../constants/time';
import { SHOW_SKIP_ACTION_BUTTON_ON_IDLE_SEC } from '../../../../../constants/components';
import { canDisintegrate, canTeleport, isUnchainedEssenceApplied } from '../../../rules/rules';
import { last } from '../../../../../helpers/arrays';

export const usePingIdlePlayer: UsePingIdlePlayer = () => {
  const { setNotification } = React.useContext(LogContext);
  const { gameState, setIdlePlayer } = React.useContext(GameContext);
  const { addMySound } = useSoundEffects({ gameState: gameState });

  const definePlayerToAct = (state: GameState) => {
    const attackingPlayer = getAttackingPlayerId(gameState);
    const isActiveAttackingPlayer = attackingPlayer === state.activePlayer;
    const isTeleportInProgress = canTeleport(gameState.turnActions) && isActiveAttackingPlayer;
    const isDisintegratorInProgress = canDisintegrate(gameState.turnActions) && isActiveAttackingPlayer;

    if (state.activeDefendingPlayer !== '') {
      if (isTeleportInProgress || isDisintegratorInProgress) return attackingPlayer;
      return state.activeDefendingPlayer;
    }
    if (isUnchainedEssenceApplied(gameState)) return last(gameState.turnActions)?.appliedTo ?? '';

    return state.activePlayer;
  };

  const [playerToAct, setPlayerToAct] = React.useState<string>();
  const [pingCount, setPingCount] = React.useState<number>();
  const [lastPingedAt, setLastPingedAt] = React.useState<number>();
  const [lastGameStateChangedAt, setLastGameStateChangedAt] = React.useState<number>();

  const currentPlayer = getCurrentPlayer(gameState);

  React.useEffect(() => {
    if (gameState.isFinished || !gameState.createdAt) {
      setIdlePlayer(undefined);
      setPlayerToAct(undefined);
      return;
    }

    const gameStateCreatedAt = new Date(gameState.createdAt).getTime();

    if (gameStateCreatedAt === lastGameStateChangedAt) return;

    setLastGameStateChangedAt(gameStateCreatedAt);
    const currentDate = Date.now();
    setLastPingedAt(currentDate);

    const newPingCount = Math.floor((currentDate - gameStateCreatedAt) / idleMilliseconds);
    setPingCount(newPingCount);

    if (newPingCount >= NO_SKIP_PLAYER_NOTIFICATION_PING_COUNT) pingActivePlayer();

    const newPlayerToAct = definePlayerToAct(gameState);
    setPlayerToAct(newPlayerToAct);

    if (getPlayer(gameState.allPlayers, newPlayerToAct).actionSkips >= AUTO_SET_PLAYER_IDLE_SKIP_COUNT) {
      setIdlePlayer(newPlayerToAct);
      setPingCount(NO_SKIP_PLAYER_NOTIFICATION_PING_COUNT);
    }
  }, [gameState]);

  React.useEffect(() => {
    if (pingCount === undefined || !playerToAct) return;
    if (pingCount < NO_SKIP_PLAYER_NOTIFICATION_PING_COUNT) return setIdlePlayer(undefined);

    setIdlePlayer(playerToAct);
    pingActivePlayerIfRelevant('error', 'Other players can skip your action now');
    pingDefendingPlayerIfRelevant('error', 'Other players can skip your defending action now');
    pingOtherPlayers('warning', 'You can skip action of ' + getPlayer(gameState.allPlayers, playerToAct).playerName);
  }, [pingCount, playerToAct]);

  const idleMilliseconds = SHOW_SKIP_ACTION_BUTTON_ON_IDLE_SEC * SECOND_IN_MILLISECONDS;

  React.useEffect(() => {
    if (!lastPingedAt) return;

    const interval = setInterval(() => {
      const currentDate = Date.now();
      if (currentDate - lastPingedAt > idleMilliseconds) {
        pingActivePlayer();
        setPingCount((prevState) => (prevState ?? 0) + 1);
        setLastPingedAt(currentDate);
      }
    }, SECOND_IN_MILLISECONDS);

    return () => {
      clearInterval(interval);
    };
  }, [lastPingedAt]);

  const pingActivePlayer = () => {
    pingActivePlayerIfRelevant('warning', 'Your action is required');
    pingDefendingPlayerIfRelevant('warning', 'Your defending action is required');
  };

  const pingDefendingPlayerIfRelevant = (type: LogType, message: string) => {
    if (gameState.activeDefendingPlayer !== playerToAct || playerToAct !== currentPlayer.id) return;
    notifyPlayer(type, message);
  };

  const pingActivePlayerIfRelevant = (type: LogType, message: string) => {
    if (gameState.activePlayer !== playerToAct || playerToAct !== currentPlayer.id) return;
    notifyPlayer(type, message);
  };

  const pingOtherPlayers = (type: LogType, message: string) => {
    if (playerToAct === currentPlayer.id) return;
    void setNotification({ type: type, text: message });
  };

  const notifyPlayer = (type: LogType, message: string) => {
    void setNotification({ type: type, text: message });
    addMySound(SoundPath.pingPlayer);
  };
};
