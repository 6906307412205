import React from 'react';
import { Props } from './ruleBookWidget.types';
import { RuleBook } from '../../ruleBook';
import { AppContext } from '../../../global/context/appContext';
import { Box, Button, IconButton, SwipeableDrawer, Tooltip, Typography } from '@mui/material';
import { sx } from './ruleBookWidget.styles';
import { AutoStories } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { tooltipStyles } from '../../../helpers/styles';

export const RuleBookWidget: React.FC<Props> = ({ defaultSearch, iconSize }) => {
  const { story, rules, cardDetails } = React.useContext(AppContext);
  const [isOpen, setIsOpen] = React.useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Tooltip title={<Typography sx={tooltipStyles}>Rule Book</Typography>} disableInteractive>
        <IconButton onClick={toggle} sx={sx.rulesButton}>
          <AutoStories sx={sx.rulesIcon(iconSize)} />
        </IconButton>
      </Tooltip>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={close}
        onOpen={open}
        PaperProps={{ sx: sx.drawer.paper }}
        sx={sx.drawer.box}
      >
        <Box sx={sx.closeButton.box}>
          <Button onClick={close} sx={sx.closeButton.item}>
            <CloseIcon />
          </Button>
        </Box>
        <RuleBook page={'Game'} story={story} rules={rules} cardDetails={cardDetails} defaultSearch={defaultSearch} />
      </SwipeableDrawer>
    </>
  );
};
