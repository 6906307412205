import { UseRevealRole } from './useRevealRole.types';
import { getAttackingPlayerId, getCurrentPlayer, getOtherPlayers, getPlayer } from '../../utils/player';
import { toImpactedAction } from '../../../utils/turnActions';
import { CardNames } from '../../../../card/cardTypes';
import { addIfNotExists } from '../../../../../helpers/arrays';
import { EssenceUpdateType } from '../../../gameContext';
import React from 'react';
import { Log, LogContext } from '../../../logContextProvider';
import { ortegonistRole } from '../../../rules/roles';
import { Player } from '../../../../player/player.types';

export const useRevealRole: UseRevealRole = ({
  gameState,
  addTurnAction,
  finishDefending,
  performFinishDefending,
  changeEssenceCount,
  finishGame,
}) => {
  const { createLog } = React.useContext(LogContext);

  const currentPlayer = getCurrentPlayer(gameState);

  const revealCurrentPlayerRoleBy = (playerId: string, cardId: string) => {
    const otherPlayers = getOtherPlayers(gameState);

    addTurnAction({
      player: playerId,
      action: toImpactedAction(CardNames.REVEALER),
      appliedTo: currentPlayer.id,
      cardIds: [cardId],
    });
    const skipDefendingLog = playerId === gameState.activeDefendingPlayer;

    if (currentPlayer.roleVisibleTo.length === otherPlayers.length) {
      const log: Log = {
        type: 'warning',
        text: `${currentPlayer.playerName}: Role is already revealed to other Players`,
      };
      addTurnAction({ player: currentPlayer.id, action: 'create log', logs: [log] });
      void createLog(log);

      finishDefending(skipDefendingLog);
      return;
    }

    const revealTo = otherPlayers.map((data) => data.id);
    revealPlayerRoleToAction(currentPlayer, revealTo);
    changeEssenceCount(currentPlayer.id, EssenceUpdateType.ADD_ONE);

    finishDefending(skipDefendingLog);
  };

  const revealRoleByDefendingPlayer = () => {
    const revealTo = getAttackingPlayerId(gameState);
    const player = getPlayer(gameState.allPlayers, gameState.activeDefendingPlayer);
    const updatedRoleVisibleToList = addIfNotExists(player.roleVisibleTo, revealTo);

    revealPlayerRoleToAction(player, updatedRoleVisibleToList);
    performFinishDefending('withRoleReveal');
  };

  const revealPlayerRoleToAction = (player: Player, revealTo: string[]) => {
    if (gameState.allPlayers.length === 0) {
      return;
    }

    player.roleVisibleTo = revealTo;

    const log: Log = {
      type: 'warning',
      text: `${player.playerName}: role revealed to ${gameState.allPlayers
        .filter((gamePlayer) => revealTo.includes(gamePlayer.id))
        .map((gamePlayer) => gamePlayer.playerName)
        .toString()}`,
    };
    addTurnAction({ player: player.id, action: 'create log', logs: [log] });
    void createLog(log);
  };

  const convertAndRevealRoleByDefendingPlayer = () => {
    const player = getPlayer(gameState.allPlayers, gameState.activeDefendingPlayer);
    player.roleCard = ortegonistRole;

    const log: Log = {
      type: 'warning',
      text: `${player.playerName}: converted to Ortegonist`,
    };
    addTurnAction({ player: player.id, action: 'create log', logs: [log] });
    void createLog(log);

    const otherPlayers = gameState.allPlayers.filter((gamePlayer) => gamePlayer.id !== gameState.activeDefendingPlayer);
    const revealTo = otherPlayers.map((data) => data.id);
    revealPlayerRoleToAction(player, revealTo);

    performFinishDefending('withConversion');
    finishGame();
  };

  return {
    revealCurrentPlayerRoleBy: revealCurrentPlayerRoleBy,
    revealRoleByDefendingPlayer: revealRoleByDefendingPlayer,
    convertAndRevealRoleByDefendingPlayer: convertAndRevealRoleByDefendingPlayer,
  };
};
