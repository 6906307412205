import { PlayingCard } from '../../../../card/cardTypes';
import { UseMoveCardAction } from './useMoveCardAction.types';
import { PlayerCardGroup, playerCardGroups } from '../../../gameContextHandler';
import { getCurrentPlayer, getPlayer } from '../../../gameContextHandler/utils/player';
import React from 'react';
import { GameContext } from '../../../gameContext';
import { removePlayerCard } from '../../gameStateOperations';
import { toAppliedAction, toRearrangedAction, toTeleportedAction } from '../../../utils/turnActions';
import { DroppableGroup } from '../../../gameContextHandler/hooks/useDragAndDrop';
import { PLAYER_CARD_GROUP_TO_LOG_TEXT, PLAYER_CARD_GROUP_TO_LOG_TYPE } from '../../../../../helpers/transformers';
import { Log, LogContext } from '../../../logContextProvider';
import { isEmpty } from '../../../../../helpers/arrays';
import { useSoundEffects } from '../useSoundEffects';

export const useMoveCardAction: UseMoveCardAction = () => {
  const { gameState, addTurnAction, finishDefending, enableDragAndDrop, withUpdatingState } =
    React.useContext(GameContext);
  const { createLog } = React.useContext(LogContext);

  const { applyCardSoundByCurrentPlayerAction } = useSoundEffects({ gameState: gameState });

  const moveCardBetweenPlayers = (
    byId: string,
    fromId: string,
    toId: string,
    card: PlayingCard,
    cardGroup: PlayerCardGroup
  ) => {
    const fromPlayer = getPlayer(gameState.allPlayers, fromId);
    const fromGroups = playerCardGroups.filter(
      (oldCardGroup) => fromPlayer[oldCardGroup].findIndex((searchedCard) => searchedCard.id === card.id) !== -1
    );
    const fromGroup = fromGroups.length > 0 ? fromGroups[0] : '';
    const byPlayer = getPlayer(gameState.allPlayers, byId);

    removePlayerCard(fromPlayer, card.id);

    const toPlayer = getPlayer(gameState.allPlayers, toId);
    if (toPlayer.id === getCurrentPlayer(gameState).id) card.isOpen = true;
    toPlayer[cardGroup] = [card, ...toPlayer[cardGroup]];

    const cardColors = !isEmpty(card.energyColors) ? card.energyColors.join('/') : '';
    const cardName = cardGroup === 'cardsInHand' ? 'Card' : cardColors + ' ' + card.name;
    const movementLog = fromId === toId ? '' : `moved from ${fromPlayer.playerName} to ${toPlayer.playerName} and `;
    const log: Log = {
      type: PLAYER_CARD_GROUP_TO_LOG_TYPE[cardGroup],
      text: `${byPlayer.playerName}: ${cardName} ${movementLog} ${PLAYER_CARD_GROUP_TO_LOG_TEXT[cardGroup]}`,
    };

    if (cardGroup !== 'cardsInHand') {
      addTurnAction({ player: byId, action: toAppliedAction(card.name), logs: [log] });
      applyCardSoundByCurrentPlayerAction(card);
    }

    if (gameState.activeDefendingPlayer) {
      finishDefending();
    }
    enableDragAndDrop(gameState.activePlayer, DroppableGroup.ALL, gameState.activePlayer);

    if (fromId === toId && fromGroup === cardGroup) {
      addTurnAction({ player: gameState.activePlayer, action: toRearrangedAction(card.name) });
      return;
    }

    if (fromId !== toId) {
      addTurnAction({
        player: toId,
        action: toTeleportedAction(cardGroup),
        cardIds: [card.id],
        appliedTo: fromId,
        logs: [log],
      });
    }

    void createLog(log);
  };

  return {
    moveCardBetweenPlayers: withUpdatingState(moveCardBetweenPlayers),
  };
};
