export type Props = {
  variant: TickerVariant;
};

export type TickerVariant =
  | 'desktopStandard'
  | 'desktopCompact'
  | 'desktopStandardWithRadiance'
  | 'desktopCompactWithRadiance'
  | 'desktopTray'
  | 'mobile';

export const defineDesktopVariant = (isCompact: boolean, radianceCount: number): TickerVariant => {
  let variant = 'desktopStandard';

  if (isCompact) {
    variant = 'desktopCompact';
  }

  if (radianceCount > 0) {
    variant += 'WithRadiance';
  }

  return variant as TickerVariant;
};
