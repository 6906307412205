import { UseGameFinish } from './useGameFinish.types';
import { getAllPlayerIds } from '../../utils/player';
import { createApiAuthRequest } from '../../utils/api';
import React from 'react';
import { useGameId } from '../useGameId';
import { Role } from '../../../../card/cardTypes';
import { FinishGameRequest, PlayerState } from '../../../gameTypes';
import { MINUTE_IN_SECONDS, SECOND_IN_MILLISECONDS } from '../../../../../constants/time';
import { formatMinutesToVisualFormat } from '../../../../../helpers/utils';
import { hasOneElement } from '../../../../../helpers/arrays';
import { Log, LogContext } from '../../../logContextProvider';
import { useGameApi } from '../../../gameApi/useGameApi';

export const useGameFinish: UseGameFinish = ({ gameState, addTurnAction }) => {
  const { createLog } = React.useContext(LogContext);

  const id = useGameId();
  const { game, finishGame: apiFinishGame } = useGameApi();

  const [gameDurationLog, setGameDurationLog] = React.useState<string>('N/A');

  React.useEffect(() => {
    if (!game || !game.startedAt || !game.finishedAt) return;

    const gameDurationInMinutes =
      (new Date(game.finishedAt).getTime() - new Date(game.startedAt).getTime()) /
      (SECOND_IN_MILLISECONDS * MINUTE_IN_SECONDS);

    setGameDurationLog(formatMinutesToVisualFormat(gameDurationInMinutes));
  }, [game]);

  const finishGame = () => {
    if (!isGameFinished() || gameState.isFinished) {
      return;
    }

    const winningRole = getGameWinningRole();

    gameState.allPlayers.map((player) => (player.isWinner = player.roleCard.role === winningRole));
    gameState.allPlayers.map((player) => (player.roleVisibleTo = getAllPlayerIds(gameState)));
    gameState.isFinished = true;

    const playerStates: PlayerState[] = gameState.allPlayers.map((player) => {
      return {
        id: player.id,
        role: player.roleCard.role,
        isWinner: player.isWinner,
      };
    });

    const finishGameRequest: FinishGameRequest = {
      finishedAt: new Date(),
      playerStates: playerStates,
    };

    apiFinishGame({ gameId: id, authRequest: createApiAuthRequest(), request: finishGameRequest });

    const log: Log = { type: 'success', text: 'Deltaration finished' };
    addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [log] });
    void createLog(log);
  };

  const getGameWinningRole = (): Role | undefined => {
    if (!isGameFinished()) {
      return undefined;
    }

    return getActiveRoles()[0];
  };

  const isGameFinished = (): boolean => {
    if (gameState.playersOrdered.length < 2) {
      return true;
    }

    return hasOneElement(getActiveRoles());
  };

  const getActiveRoles = () =>
    Array.from(
      new Set(
        gameState.allPlayers
          .filter((player) => gameState.playersOrdered.includes(player.id))
          .map((player) => player.roleCard.role)
      )
    );

  return {
    finishGame: finishGame,
    getGameWinningRole: getGameWinningRole,
    gameDurationLog: gameDurationLog,
  };
};
