import React, { ReactElement } from 'react';
import { Props } from './currentPlayerDrawer.types';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { CurrentPlayerBoard } from '../currentPlayerBoard';
import { CurrentPlayerTray } from '../currentPlayerTray';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { sxInit } from './currentPlayerDrawer.styles';
import { AppContext } from '../../../../global/context/appContext';
import { isTrayAllowed } from '../../../../helpers/styles';
import { useCurrentSize } from '../../../card/hooks/useCurrentSize';
import { DroppableRadiance } from '../../droppable/droppableRadiance';

export const CurrentPlayerDrawer: React.FC<Props> = ({ isOpen, onOpen, onClose, playerProps }) => {
  const { windowSize } = React.useContext(AppContext);
  const currentSize = useCurrentSize();

  const isTrayAvailable = isTrayAllowed(windowSize);
  const currentPlayer = playerProps.player;
  const playerFullSize = <CurrentPlayerBoard {...playerProps} size={currentSize} />;
  const playerTray = <CurrentPlayerTray {...playerProps} size={currentSize} />;

  const sx = sxInit(currentSize, !isOpen && isTrayAvailable);

  const button = (icon: ReactElement, onClick: () => void) => (
    <Button onClick={onClick} sx={sx.button}>
      {icon}
    </Button>
  );

  const expandButton = isTrayAvailable && (
    <Box sx={sx.expandButtonBox}>
      {isOpen ? button(<ExpandMore sx={sx.expandIcon} />, onClose) : button(<ExpandLess sx={sx.expandIcon} />, onOpen)}
    </Box>
  );

  const drawer = (isDrawerOpen: boolean, component: ReactElement) => (
    <SwipeableDrawer
      variant="persistent"
      anchor="bottom"
      open={isDrawerOpen}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{ sx: sx.drawer }}
    >
      <DroppableRadiance playerId={currentPlayer.id}>
        <Box>
          {expandButton}
          {component}
        </Box>
      </DroppableRadiance>
    </SwipeableDrawer>
  );
  return (
    <div>
      <React.Fragment key="current-player-drawer">
        {isOpen ? drawer(isOpen, playerFullSize) : drawer(!isOpen, isTrayAvailable ? playerTray : <></>)}
      </React.Fragment>
    </div>
  );
};
