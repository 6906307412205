import React from 'react';
import { Props } from './pulsatingCard.types';
import { cardToComponent } from '../../helpers/transformers';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { Card } from '../card';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { withCardOffset } from '../../helpers/location';
import styles from './pulsatingCard.module.css';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { AppContext } from '../../global/context/appContext';

export const PulsatingCard: React.FC<Props> = ({ card, displayLocation, cardLocation, runAfter }) => {
  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { dimension } = useCardDimension({ size: cardSize });
  const { windowSize } = React.useContext(AppContext);
  const [show, setShow] = React.useState(true);
  const [translateProps, setTranslateProps] = React.useState({ translateX: '0px', translateY: '0px' });

  const checkedDisplayLocation = withCardOffset(displayLocation, dimension);
  const checkedCardLocation = cardLocation ? withCardOffset(cardLocation, dimension) : checkedDisplayLocation;

  React.useEffect(() => {
    const adjustedSafeDimension = isMobile ? dimension * 0.6 : dimension * 1.2;
    const maxX = windowSize.width - adjustedSafeDimension;
    const maxY = windowSize.height - adjustedSafeDimension;
    let newX = checkedCardLocation.x;
    let newY = checkedCardLocation.y;
    let translateX = '0px';
    let translateY = '0px';

    if (checkedCardLocation.x < adjustedSafeDimension || checkedCardLocation.x > maxX) {
      newX = checkedCardLocation.x < adjustedSafeDimension ? adjustedSafeDimension : maxX - adjustedSafeDimension;
      translateX = `${newX - checkedCardLocation.x}px`;
    }

    if (checkedCardLocation.y < adjustedSafeDimension || checkedCardLocation.y > maxY) {
      newY = checkedCardLocation.y < adjustedSafeDimension ? adjustedSafeDimension : maxY - adjustedSafeDimension;
      translateY = `${newY - checkedCardLocation.y}px`;
    }

    setTranslateProps({ translateX, translateY });
  }, []);

  const handleAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName.includes('moveToSafeAndBack')) {
      if (runAfter) runAfter();
      setShow(false);
    }
  };

  return (
    <div className={styles.container} style={{ left: checkedCardLocation.x, top: checkedCardLocation.y }}>
      {show && (
        <div
          className={styles.moveToSafeAndBack}
          style={
            {
              '--safeX': translateProps.translateX,
              '--safeY': translateProps.translateY,
            } as React.CSSProperties
          }
          onAnimationEnd={handleAnimationEnd}
        >
          <div className={cn(styles.pulsatingCard, { [styles.isMobile]: isMobile })}>
            <Card {...cardToComponent(card, cardSize)} showImageOnly={isMobile} />
          </div>
        </div>
      )}
    </div>
  );
};
