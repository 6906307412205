import React from 'react';

export type Props = {
  children?: React.ReactNode;
};

export type LogType = 'success' | 'info' | 'warning' | 'error';
export type FetchLogsAction = () => Promise<void>;
export type CreateLogAction = (log: Log) => Promise<void>;
export type CreateLogsAction = (logs: Log[]) => Promise<void>;

export type Log = {
  type: LogType;
  text: string;
};

export type LogContextType = {
  logs: Log[];
  createLog: CreateLogAction;
  createRealtimeLog: (log: Log) => void;
  applyLogs: CreateLogsAction;
  fetchLogs: FetchLogsAction;
  logWorker: Worker | undefined;
  setNotification: CreateLogAction;
  isLogJournalOpen: boolean;
  setIsLogJournalOpen: (isOpen: boolean) => void;
};

export const LogContext = React.createContext({} as LogContextType);

export const LOG_JOURNAL_NAME = 'Game log journal';
